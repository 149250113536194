export const navigateToWrapper = async (url, options) => {
    options = options || {};
    if (typeof(window) != 'undefined') {
        if (useNuxtApp().isHydrating) {
            return;
        }

        if (! options?.open?.target) {
            pageUnmoutedCode();
        }

        if (window.location.href != getUrl(url)) {
            await navigateTo(url, options)
        } else {
            options.external = true;
            await navigateTo(url, options)
        }

    } else {
        /*
            This is on the server side.
            Must use the return statement because this is called inside our route middleware.  Otherwise,
            the 301 redirect for the "enforce login" functionality is not sent to the browser, and the user 
            is not taken to the login page.
        */
        return await navigateTo(url, options);  
    }

}