export const useAdminFlags = () => useState("adminFlags", () => {});
export const useSocialSignin = () => useState("social_signin_enabled", () => false);

export const useRunbookSearchResultState = () => useState("runbook_search_result_state", () => []);

export const useLoadingState = () => useState("loading", () => false);
export const useAuthState = () => useState("auth", () => false);
export const useExecutionParamsVisibleState = () => useState("execution_params_visible", () => false);

export const useIsEdittingState = () => useState("is_editting", () => false);

export const useLaunchEditingModeState = () => useState("launch_editting_mode_task", () => '');
export const useBestProxyState = () => useState("best_proxy", () => '');
export const useGeneratedAccessTokenState = () => useState("generated_access_token", () => '');

export const useAddEmptyTaskForAgentPageState = () => useState("add_empty_task_for_agent_page", () => 0);
export const useExecutingTaskIndexState = () => useState("executing_task_index_state", () => ''); // Capture the normalized index path of the executing task. 

export const useAgentMessageState = () => useState("agent_message_state", () => ''); // Use together with launchEditingMode for the "agent" page
export const usePageMountedState = () => useState("page_mounted_state", () => false); // Use together with launchEditingMode for the "agent" page

export const useForceTaskReloadOnExceptionState = () => useState("force_task_reload_on_exception", () => ''); // Use to force the task to redisplay code / commands when there is an exception on the agent page
export const useTrackTaskTitleState = () => useState("track_task_title_state", () => ''); // Use to track changes in the title between TaskTitleContainer and CreateReadEdit

export const useTrackTaskDeletedEventState = () => useState("track_task_deleted_event_state", () => 1); // Use to know when a task was deleted on the agent page

export const useForceRerenderChildTasksState = () => useState("force_rerender_child_tasks_state", () => ''); // 

export const useAddEmptyChildState = () => useState("add_empty_child_state", () => ''); // Use on the agent page to tell a task to add an empty child if another one does not already existed

export const useTaskDeletingState = () => useState("task_deleting", () => '');
export const useLastAlertTimestampState = () => useState("last_error_alert_timestamp", () => 0);
export const useForceRerenderRouteState = () => useState("force_rerender_route", () => 0);
export const useUserManagementTabState = () => useState("user_management_tab_state", () => 'users');

export const useButtonClickState = () => useState("button_click_state", () => '');
export const useTrackCurrentRetryState = () => useState("track_current_retry_state", () => 0);

export const useSettingsTabState = () => useState("settings_tab_state", () => 'general');

export const useSearchPowerResultState = () => useState('search_powered_results', () => []);

export const useEnvState = () => useState('env_state', () => {}); 

export const useHasLogoTagState = () => useState('has_logo_tag_state', () => {}); 

export const useShowAllTasksState = () => useState('show_all_tasks_filter', () => 0); 

export const useSearchRunbookTextState = () => useState('search_runbook', () => '');
export const useTaskDetailsRightColumnFilterState = () => useState('task_details_right_column_filter', () => 'both');
export const useTaskDetailsRightColumnShowLoadMoreState = () => useState('task_details_right_column_show_load_more', () => false);

export const useViewControlIOState = () => useState('view_control_io', () => true);
export const useViewControlCodeState = () => useState('view_control_code', () => true);
export const useViewControlDescriptionState = () => useState('view_control_description', () => true);
export const useViewControlResultsState = () => useState('view_control_results', () => true);

export const useCollabModalTeleportState = () => useState("collab_modal_teleport", () => false); 
export const useCollabModalReadyState = () => useState("collab_modal_ready", () => false); 

export const useSubmitToSpacesModalTeleportState = () => useState("submit_to_spaces_modal_teleport", () => false); 
export const useSubmitToSpacesModalReadyState = () => useState("submit_to_spaces_modal_ready", () => false);

export const useJobCardForceUpdateTimestampKeyState = () => useState("job_card_force_update_timestamp_key", () => ''); 

export const useUpdateNotificationBellState = () => useState("update_notification_bell", () => 0);

export const useAddAboveOrBelowState = () => useState("add_above_or_below_state", () => ''); // Use to capture which button / icon was clicked
export const useAddAboveOrBelowStepNumberState = () => useState("add_above_or_below_step_number_state", () => ''); // Use to capture step number corresponding the above

export const useTaskBeingEditedStepNumberState = () => useState("task_being_edited_step_number_state", () => ''); // Capture the step number of the task being edited.  Used to scroll the task into view after saving.
export const useScrollSavedTaskIntoView = () => useState("scroll_saved_task_into_view", () => ''); // After a task is saved, it should be scrolled into view.  Used together with useTaskBeingEditedStepNumberState.


export const useGuidedTourVisable = () => useState("guided_tour_visible_state", () => false);

export const useTrackSelectedProxyState = () => useState("track_selected_proxy_state", () => false);

export const useSpaceDropdownState = () => useState("space_dropdown_state", () => '');
export const useIsCopilotPageState = () => useState("is_copilot_page_state", () => false);

export const addAuthHeaders = async (headers, request, settings, community_url) => {
  // The addAuthHeaders is being used on the client side
  if (process.server) {
    const runtimeConfig = useRuntimeConfig();
    let token = runtimeConfig.public.cors_jwt_token;
    if ((token != null) && (token != '')) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  } else {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (isAuthenticated == "1") {
      const runtimeConfig = useRuntimeConfig();
      let token = '';

      // If the CORS_JWT_TOKEN environment variable is configure, used it
      token = runtimeConfig.public.cors_jwt_token;

      // For our own development need, if we want to use another server as a community server, but for whatever reason,
      // that server is behind a login screen (the ENFORCE_LOGIN environment is set to true on that server), we need to 
      // supply a token for the search functionality to work, we have to get the community_url from the integration card 
      // (settings), compare it with the request URL, and if the integration is enabled, and has a token, then use that 
      // token.

      if (
        (settings.hasOwnProperty('admin_settings')) && 
        (settings.admin_settings.hasOwnProperty('flag_enable_dagknows_community')) &&
        (settings.admin_settings.flag_enable_dagknows_community) &&
        (settings.hasOwnProperty('dagknows_community_credentials')) &&
        (settings.dagknows_community_credentials.hasOwnProperty('token'))
      ) {
        if ((community_url != '') && (request.startsWith(community_url))) {
          token = settings.dagknows_community_credentials['token'];
        }
      }

      if ((token != null) && (token != '')) {
        headers["Authorization"] = `Bearer ${token}`;
      }
    } else {
      /*
            When the user is not authenticated, we still need to render the page for the 
            anonymous user as though this user was the Google crawler, but because we do not 
            have a token, we may have to use the API key, but we do not want to expose the 
            API to the public, so we have to first hit the Nuxt /api endpoint, which then hit 
            the apigateway.  We must ensure that the Nuxt /api endpoint do not allow any POST, 
            PUT and DELETE.  Anyhow, because the user is not authenticated, we would not add 
            any header here.  This function should be called inside the Nuxt /api endpoint, 
            and that should go into the process.server section above.
            */
    }
  }
};


