import { setPageMountedFalse } from "~/composables/setPageMountedFalse";

export default defineNuxtRouteMiddleware(async (to, from) => {
    /*
    The name of this file is vLoginRedirectHelper.  The initial goal of this file was to remember the page that the user
    was previously looking at before the user sign into the application so that after signing into the application, we 
    can take the user back to that page so that the user can continue to engage with that page.  Currently, when the 
    user is on a page, and click on the login button, it takes the user to the login page.  Instead of taking the user 
    to the login page, perhaps, we can display a modal dialog, and reload the page.  Not sure.

    The purpose of this file now includes:
    1.  "Login redirect helper"
    2.  "Centralize checking for required authentication or not"
    3.  "MFA"

    We probably should break this file apart, but I am not sure if Nuxt allows us to have multiple route middleware, 
    what order they are run in, and whether there is any potential issues with having multiple route middleware or not.
    */

    /*
    to.params.id 
    to.path
    */

    if (typeof(window) != 'undefined') {
        await showWaitPage();
    }
    setPageMountedState(false);
    let is_copilot_page_state = useIsCopilotPageState();
    /* Start: "Login redirect helper" */
    let to_path = to.path;
    let no_leading_slash = to_path.replace(/^\//,'');
    if (! (no_leading_slash.search(/(vlogin|vLogin|vlogout|_nuxt|favicon|vstatic|edit-user-profile|change-password|setupMFA|verifyMFA)/) >= 0)) {
        const loginRedirect = useCookie('vloginRedirect', {
            'sameSite': 'lax'
        });
        /*
        Store the path of the page in a cookie so that we know which page we need to display after the user 
        successfully authenticated, either using our authentication or social sign-in.

        The point is to be able to take the user to the page for the task that the use was reading before signing into 
        the application so that the user can continue with that task, whether to leave a comment, request access, 
        contribute / edit if the user has the appropriate access, etc without having to search to locate the task again.

        After the user edit his/her profile, or change the password, whether we should take the user back to that page 
        is really minor.
        */
        loginRedirect.value = to.fullPath;
    }
    /* End: "Login redirect helper" */

    /* Start: "Some clean up stuff" */
    if (typeof(window) != 'undefined') {
        window.dagknows = {};
        window.dk = {}
    }
    /* End: "Some clean up stuff" */

    /* Start: "Centralize checking for required authentication or not" */
    let is_authenticated = isAuthenticated();
    let userInfoStore = useUserInfoStore();
    let user_info = undefined;
    if (! is_authenticated) {
        // If the user is not authenticated, check again
        user_info = await getCurrentUserInfo();
        userInfoStore.setUserInfo(user_info);
        is_authenticated = isAuthenticated();
    }
    
    let always_required_authentication = [
        'task-create',
        'task-create-ai',
        'vintegrations',
        'change-password',
        'my-runbooks',
        'download',
        'user-management',
        'edit-user-profile',
        'shared-with-me',
        'vsettings',
        'my-drafts',
        'cli-sessions'
    ]

    let anonymous_pages = [
        'signup',
        'vlogin',
        'interactive-demo'
    ]

    if ((! is_authenticated) && (! no_leading_slash.includes('_nuxt')) && (! no_leading_slash.includes('vLoginRedirect'))) {
        let parts = no_leading_slash.replace(/\/+$/,'').split(/\//);
        let last_part = parts.pop();
        if (last_part.startsWith('n/')) {
            parts = last_part.split(/\//);
            last_part = parts.pop();
        }
        if ((always_required_authentication.includes(last_part)) || (no_leading_slash.includes('cli-sessions'))) {
            return await navigateToWrapper('/vlogin');
        } else if (anonymous_pages.includes(last_part)) {
            // We do not have to do anything for pages that can be access anonymously
        } else {
            let enforce_login = await getEnforceLogin();
            if ((enforce_login) && (! isAuthenticated()) && (last_part != 'vlogin')) {
                return navigateToWrapper('/vlogin');
            }
        }
    }
    /* End: "Centralize checking for required authentication or not" */

    /* Start: MFA */
    user_info = userInfoStore.getUserInfo;
    let check_mfa = true;
    //console.log('USER_INFO:', user_info)

    if (! is_authenticated) {
        // If the user is not authenticated, there is nothing we can or need to do.
        check_mfa = false;
    } else if ((to_path.includes('setupMFA')) || (to_path.includes('verifyMFA'))) {
        // This is the setupMFA page, just render it.
        check_mfa = false;
    } else if (user_info['org'].startsWith('trial')) {
        check_mfa = false;
    }

    if (check_mfa) {
        if (user_info['org_has_mfa_enabled']) {
            if ((user_info['mfa_configured'] == null) || (user_info['mfa_configured'] == false) || (user_info['mfa_configured'] == '0')) {
                return await navigateToWrapper('/n/setupMFA');
            } else if (! user_info['mfa_verified']) {
                return await navigateToWrapper('/n/verifyMFA')
            }
        }
    }
    /* End: MFA */

    if (to.fullPath.includes('agent=1')) {
        is_copilot_page_state.value = true;
    } else {
        is_copilot_page_state.value = false;
    }
});